import React from "react"
import loadable from "@loadable/component"
const Centered = loadable(() => import("./Centered"))
const CardLeft = loadable(() => import("./CardLeft"))
const CardRight = loadable(() => import("./CardRight"))
const StepUp = loadable(() => import("./StepUp"))
const StepDown = loadable(() => import("./StepDown"))

export const New = ({ ThreeGridFeaturesBlock, theme }) => {
  const { type } = ThreeGridFeaturesBlock
  switch (type) {
    case "Centered":
      return <Centered {...ThreeGridFeaturesBlock} theme={theme} />
    case "Left":
      return <CardLeft {...ThreeGridFeaturesBlock} theme={theme} />
    case "Right":
      return <CardRight {...ThreeGridFeaturesBlock} theme={theme} />
    case "StepUp":
      return <StepUp {...ThreeGridFeaturesBlock} theme={theme} />
    case "StepDown":
      return <StepDown {...ThreeGridFeaturesBlock} theme={theme} />
    default:
      break
  }
}

export default New
